<template>
    <div class="v-sort-tables">
        <table :class="class_name">
            <thead>
                <tr>
                    <td v-for="column_item in columns_list"
                        :key="column_item.key">
                        {{ column_item.name }}

                        <i v-if="column_item.sort" 
                           class="bi bi-arrow-down-up sort-icon"
                           @click="sortBy(column_item)"></i>
                    </td>
                </tr>
            </thead>
            <slot></slot>
        </table>
    </div>
</template>

<script>
// bootstrap 表格 带排序

import "bootstrap-icons/font/bootstrap-icons.css"

export default {
    name: 'VSortTables',
    props: {
        // bootstrap类名，用于切换table样式
        class_name: {
            type: String,
            default: ""
        },
        // 展示的数据列表
        data_list: {
            type: Array,
            default: () => []
        },
        // 表头list   [{key:xxx, name:xxxx, sort: true}, ...]
        columns_list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            sortBy(column_item) {
                this.$emit('sort', column_item);
            }
        }
    },
    created() {

    },

    methods: {
    }
}
</script>

<style scoped lang="css">
    .sort-icon {
        padding: 0px 5px;
        cursor: pointer;
        color: rgb(109, 105, 105);
    }
</style>
